<template lang="pug"> 
  div
    b-modal#modal-prevent-closing-new-inventory(ok-title="Nouvel inventaire" hide-footer cancel-title="Annuler" size="lg" ref='modal-new-inventory' title='Nouvelle facture' @ok='handleOk' :header-class="'bg-light-primary'" :ok-disabled="!selectStorageLocation")
      template.d-block(#modal-header="{ close }")
        div.w-100
          button.absolute(type="button" aria-label="Close" class="close" @click="close()") ×
          b-row.my-0
            b-col.py-0.mb-0(cols='12' style="padding:0px !important")
              h3.text-primary Nouvel inventaire
      div(v-if="institutionSelected != null")
          .d-flex
            .list-buttom-custom.m-1(v-for="(storageLocation, index) of storageLocationsList")
                div.relative.doc-buttom-custom(@click="handleSubmit(storageLocation.id)")
                    img.w-100(:src="getSrcIcon(storageLocation.storageLocationType)")
                    h5.mt-1.text-primary.text-center {{ storageLocation.label }}

</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { mapGetters, mapActions } from "vuex";
import vSelect from "vue-select";
import dayjs from "dayjs";
import { formatDate } from "@/types/api-orisis/library/FormatOperations.ts";

export default {
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
    "v-select": vSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data: () => ({
    submittedNames: [],
    selectStorageLocation: null,
    tabActive: "blank-inventory",
  }),
  props: {
    affairId: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapGetters([
      "isLoadingStorageLocationsList",
      "storageLocationsList",
      "isLoadingInstitutionsList",
      "institutionsList",
      "institutionSelected",
      "workspaceSelected",
    ]),
    document: {
      get() {
        return this.$store.getters.document;
      },
      set(value) {
        return this.$store.commit("SET_DOCUMENT", value);
      },
    },
  },
  async created() {
    await this.getStorageLocations({});
  },
  methods: {
    ...mapActions(["getStorageLocations", "getInventoryEmpty"]),
    formatDate,
    getSrcIcon(type) {
      switch (type) {
        case 1:
          return require("@/assets/icones/icon-doc-inventory-truck.svg");
          break;
        default:
          return require("@/assets/icones/icon-doc-inventory-warehouse.svg");
          break;
      }
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    async handleSubmit(storageLocationId = null) {
      if (storageLocationId != null) {
        this.$refs["modal-new-inventory"].toggle("#toggle-btn-new-inventory");
        this.$store.commit(
          "SET_IS_GLOBAL_MESSAGE",
          "Création de l'inventaire en cours..."
        );
        this.$store.commit("SET_IS_GLOBAL_CREATING", true);
        await this.getInventoryEmpty({
          storageLocationId: storageLocationId,
        })
          .then((inventory) => {
            this.$store.commit("SET_IS_GLOBAL_CREATING", false);
            this.$router.push({
              name: "new-inventory",
              params: {
                id: inventory.id,
                title: "Nouvel inventaire",
                tips: "Nouvel inventaire",
                routeOrigine: "stock/inventories",
              },
            });
          })
          .catch((error) => {
            this.$store.commit("SET_IS_GLOBAL_CREATING", false);
          });
      }
    },
    toggleModal() {
      this.$refs["modal-new-inventory"].toggle("#toggle-btn-new-inventory");
    },
  },
};
</script>

<style>
#modal-prevent-closing-new-inventory .modal-header {
  padding-bottom: 0 !important;
}
</style>
