<template lang="pug">
  div.h-100
    .h-100(v-if='isLoadingInventory || isUpdatingInventory || isDeletingInventory')
      .text-center.flex-center.h-100
        .loading-bg-inner
          .loader
            .outer
            .middle
            .inner
        div(style="margin-top: 7rem;")
          | Chargement des d&eacute;tails de l&apos;inventaire...
    .h-100(v-else)
      .sidebar-header.pt-1.px-2
        div
          h3 {{ "Inventaire : " + (inventory.documentReference ? inventory.documentReference : "Numéro en attente") }}
        feather-icon.close-icon.cursor-pointer(icon='XIcon', size='24', @click.stop='closeSidebar')
      .sidebar-header.pb-1.px-2
        .d-flex.align-items-center
          Status(:statusId="inventory.status" nature="inventoryForm")
          span {{ inventory.inventoryDate ? "En date du : " + computedInventoryDate : "" }}
        .end
      vs-divider.m-0
      .sidebar-action.py-05.px-2
        .d-flex.align-items-center.justify-content-between.w-100
          b-button(v-if="archived" variant="warning" class="btn-icon" @click="restoreInventoryLocal(inventory.id)")
            feather-icon.mr-50(icon="ArrowDownIcon") 
            | Restaurer
          b-button-group(v-else)
            b-button.btn-icon.d-flex(v-if='inventory.storageLocationId' variant='primary' @click="editInventory(inventory.id, 'inventory-pdf')" :style="'border-right:1px solid #ccc !important;'")
              feather-icon(icon='EyeIcon')
            b-button.btn-icon.d-flex(v-if='inventory.status < 3' style='border-left:1px solid #ccc !important;' variant='primary' @click="editInventory(inventory.id, 'inventory-edit')")
              feather-icon(icon='EditIcon')
              span(style='margin-left:3px') Modifier
            b-dropdown.dropdown-icon-wrapper(v-if='inventory.status >= 3 && inventory.path' variant='primary' style='border-left:1px solid #ccc !important; ' left='')
              template(#button-content='')
                span(style='margin-right:3px') T&eacute;l&eacute;charger
                feather-icon.align-middle(icon='DownloadIcon')
              b-dropdown-item(@click.stop='forceFileDownload(inventory.path)') T&eacute;l&eacute;charger
              b-dropdown-item(@click="editInventory(inventory.id, 'inventory-pdf')") Voir et Imprimer
            
          .d-flex.align-items-center.justify-end(v-if="inventory.status < 2")
            b-button-group
              b-button(v-if="archived" variant="outline-danger" class="btn-icon" @click="deleteInventoryLocal(inventory.id)")
                feather-icon.mr-50(icon="TrashIcon") 
                | Supprimer définitivement
              b-button(v-else variant="outline-danger" class="btn-icon" @click="archiveInventoryLocal(inventory.id)")
                feather-icon(icon="ArchiveIcon")
      vs-divider.m-0
      b-row.content-scrollable-sticky.m-0.justify-content-center
          b-col#nav-sticky-horizontal(style="padding: 0 !important; height: 100% !important;" cols='12')
            ul.d-flex.border-bottom-grey-light.px-1
              li
                a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'details' ? 'current' : ''" @click="tabActive = 'details'")
                  span Détails
              li(v-if="haveGedModule")
                a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'files' ? 'current' : ''" @click="tabActive = 'files'")
                  span Documents
              li
                a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'notes' ? 'current' : ''" @click="tabActive = 'notes'")
                  span Notes
                  feather-icon.align-middle.ml-50(v-if="inventory.internalNote" icon='MessageCircleIcon')
      .sidebar-content.with-tabs
        div(v-if='tabActive == "details"')
          div
            .d-flex.justify-content-between
              h5.mb-0.d-flex.align-items-center.mb-0.text-primary
                span.d-flex.align-items-center(style='min-width: fit-content; margin-right: -5px')
                  feather-icon(icon='ChevronRightIcon') 
                  | Détails
              .d-flex
            ul.listTab.mt-1
              li(v-if='inventory.userId')
                span Traité par
                span {{ inventory.userFirstName + " " + inventory.userLastName }}
              li(v-if='countInventoryValues')
                span Nb de produits
                span {{countInventoryValues.numberProducts}}
              li(v-if='countInventoryValues')
                span Valeur estimée
                span {{ formatCurrency(countInventoryValues.inventoryValue.toFixed(2)) }}
            vs-divider
          div(v-if='inventory.storageLocationId')
            .d-flex.justify-content-between
                h5.mb-0.d-flex.align-items-center.mb-0.text-primary.cursor-pointer(@click="editStorageLocation(inventory.storageLocationId)" @mouseover="showStorageLocationEdit=true" @mouseleave="showStorageLocationEdit=false" style="flex: 1;")
                  .d-flex.align-items-center(style='min-width: fit-content; margin-right: -5px')
                    feather-icon(icon='ChevronRightIcon') 
                    | {{ inventory.storageLocation.label }}
                    feather-icon.ml-50(v-show="showStorageLocationEdit" icon='ExternalLinkIcon')
                .d-flex
                  div(style='width: 10px; height:20px; background-color: #eef1f6; clip-path: polygon(100% 100%, 100% 0, 0 50%);')
                  div(style="background:#eef1f6 !important; height: 20px; min-width: fit-content;")
                    div.px-50
                      small Point de stockage {{ inventory.storageLocationId }}
            div
              span(v-show='inventory.storageLocation.address') {{ inventory.storageLocation.address }}
                br
              span(v-show='inventory.storageLocation.addressComplement') {{ inventory.storageLocation.addressComplement }}
                br
              span(v-show='inventory.storageLocation.zipCode || inventory.storageLocation.city') {{ inventory.storageLocation.zipCode }} {{ inventory.storageLocation.city }}
                br
              span(v-show='inventory.storageLocation.country') {{ inventory.storageLocation.country }}
            ul.listTab.mt-1(v-if='inventory.storageLocationId')
              li(v-if='inventory.storageLocation.email')
                span Email
                span {{ inventory.storageLocation.email }}
              li(v-if='inventory.storageLocation.phoneNumber')
                span Téléphone
                span {{ inventory.storageLocation.phoneNumber }}
            vs-divider 
          
          div(v-if='inventory.collaborator')
            vs-divider.pl-0.m-0.mt-1.mb-50.text-primary(color='#cccccc' position='left')
              span.text-primary Trait&eacute; par
            h4.mb-1.text-primary.d-flex.justify-content-between
              span
                | {{inventory.collaborator.firstName + &apos; &apos; + inventory.collaborator.lastName }}
              span
                feather-icon.cursor-pointer(icon='ExternalLinkIcon')
            ul.listTab
              li
                span Adresse e-mail
                span {{inventory.collaborator.email}}
              li
                span T&eacute;l&eacute;phone
                span {{inventory.collaborator.phoneNumber}}
        div.h-100(v-if='tabActive == "files"')
          ged-viewer-sidebar(:parentFolder="inventory.folderId" :fromModule="'inventory'")
        div.h-100(v-if='tabActive == "notes"')
          quill-editor(id="text-area-designation" v-model="inventory.internalNote" toolbar="essential" @blur="updateInventoryWithoutLoading" class="editor quill-fixed" :options="editorOption" style="height: calc(100% - 55px);")
  </template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapGetters, mapActions } from "vuex";
import { inventoryStatusTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";
import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";

import Status from "@/components/global/status/Status";

import axios from "axios";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import { checkFunctionUser } from "@/auth/utils.ts";
import GedViewerSidebar from "@/components/ged/GedViewerSidebar";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

export default {
  data() {
    return {
      archived: false,
      tabActive: "details",
      showStorageLocationEdit: false,
      editorOption: {
        placeholder: "Ecrivez une note interne lié à ce document ...",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [
              { align: "" },
              { align: "center" },
              { align: "right" },
              { align: "justify" },
            ],
            [{ color: [] }, { background: [] }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }],
            ["link"],
            ["clean"],
          ],
        },
      },
      dataOrigine: {},
      countInventoryValues: {
        numberProducts: 0,
        inventoryValue: 0,
      },
    };
  },
  computed: {
    ...mapGetters([
      "inventoryDetails",
      "isLoadingInventory",
      "isUpdatingInventory",
      "isDeletingInventory",
      "workspaceSelected",
    ]),
    inventory: {
      get() {
        return this.$store.getters.inventory;
      },
      set(value) {
        return this.$store.commit("SET_INVENTORY", value);
      },
    },
    computedInventoryDate() {
      return dayjs(this.inventory.inventoryDate).format("DD/MM/YYYY");
    },
    haveGedModule() {
      return (
        this.checkFunctionUser(42, "all") && this.checkFunctionUser(43, "all")
      );
    },
  },
  methods: {
    ...mapActions([
      "updateInventory",
      "deleteInventories",
      "isArchivingInventory",
      "archiveInventories",
      "getInventoryById",
      "getInventoryArchivedById",
      "restoreInventories",
    ]),
    checkFunctionUser,
    inventoryStatusTranslate,
    formatCurrency,
    async getInventoryArchivedDetails(id, archived) {
      this.archived = true;
      this.tabActive = "details";
      if (id > 0) {
        await this.getInventoryArchivedById({
          inventoryId: id,
        }).then((res) => {
          this.dataOrigine = JSON.parse(JSON.stringify(res));
        });
        this.countInventory();
      }
    },
    async getInventoryDetails(id) {
      this.archived = false;
      this.tabActive = "details";
      if (id > 0) {
        await this.getInventoryById({
          inventoryId: id,
        }).then((res) => {
          this.dataOrigine = JSON.parse(JSON.stringify(res));
        });
        this.countInventory();
      }
    },
    async updateInventoryWithoutLoading() {
      if (this.dataOrigine.internalNote !== this.inventory.internalNote) {
        await this.updateInventory({
          inventory: this.inventory,
          updateState: false,
        });
      }
    },
    countInventory() {
      this.countInventoryValues = {
        numberProducts: 0,
        inventoryValue: 0,
      };
      if (this.inventoryDetails) {
        for (let index = 0; index < this.inventoryDetails.length; index++) {
          const element = this.inventoryDetails[index];
          this.countInventoryValues.numberProducts += element.quantity;
          this.countInventoryValues.inventoryValue +=
            element.quantity * element.product.priceHT;
        }
      }
    },

    async editStorageLocation(id) {
      this.$emit("closeSidebar");
      this.$router.push({
        name: "edit-storage-location",
        params: {
          id: id,
          title: "Edit : " + this.orderFormProvider.storageLocationLabel,
          tips:
            "Editer le point de stockage : " +
            this.orderFormProvider.storageLocationLabel,
          routeOrigine: "stock/inventories",
        },
      });
    },

    async editInventory(id, mode) {
      this.$emit("closeSidebar");
      this.$router.push({
        name: "edit-inventory",
        params: {
          mode: mode,
          id: id,
          title: this.inventory.documentReference
            ? this.inventory.documentReference
            : "Editer un inventaire",
          tips:
            "Editer l'inventaire" + this.inventory.documentReference
              ? " : " + this.inventory.documentReference
              : "",
          routeOrigine: "stock/inventories",
        },
      });
    },
    forceFileDownload(donwload) {
      // TODO : ATTENTION FONCTIONNE PAS LOCAL, EXIGE CORS UNCLOCK
      axios({
        url: donwload,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        var fullUri = document.createElement("a");
        fullUri.href = fileURL;
        fullUri.setAttribute("download", this.inventory.documentReference);
        document.body.appendChild(fullUri);
        fullUri.click();
        URL.revokeObjectURL(fullUri.href);
      });
    },
    archiveInventoryLocal(id) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourrez à tout moment restaurer cette fiche en consultant la liste de vos archives.",
          {
            title: "Êtes-vous sûr de vouloir archiver l'inventaire ?",
            size: "sm",
            okVariant: "danger",
            okTitle: "Archiver",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.archiveInventories({
              inventoryIds: [id],
            }).then((res) => {
              this.$nextTick(() => {
                this.$emit("closeSidebar");
                this.$emit("refreshGrid");
              });
            });
          }
        });
    },
    restoreInventoryLocal(id) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourez ré-utiliser cette fiche dans tous les modules de votre application.",
          {
            title: "Êtes-vous sûr de vouloir restaurer l'inventaire ?",
            size: "sm",
            okVariant: "warning",
            okTitle: "Restaurer",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.restoreInventories({
              inventoryIds: [id],
            }).then((res) => {
              this.$nextTick(() => {
                this.$emit("closeSidebar");
                this.$emit("refreshGrid");
              });
            });
          }
        });
    },
    deleteInventoryLocal(id) {
      this.$bvModal
        .msgBoxConfirm("Cette action est définitive et irréversible.", {
          title: "Êtes-vous sûr de vouloir supprimer l'inventaire ?",
          size: "sm",
          okVariant: "danger",
          okTitle: "Supprimer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deleteInventories({
              inventoryIds: [id],
            }).then((res) => {
              this.$nextTick(() => {
                this.$emit("closeSidebar");
                this.$emit("refreshGrid");
              });
            });
          }
        });
    },
    closeSidebar() {
      this.$emit("closeSidebar");
    },
  },
  components: {
    VuePerfectScrollbar,
    quillEditor,
    GedViewerSidebar,
    Status,
  },
};
</script>
<style lang="scss" scoped>
.cancel-modal-buttons {
  display: flex;
  justify-content: center;
}

.dropdown {
  position: relative;
  display: inline-block;
  font-size: 15px;
  cursor: pointer;

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 5px;

    .dropdown-content-item {
      padding: 10px 10px 10px 10px;
      cursor: pointer;
      font-size: 14px;

      &:hover {
        background-color: #eeeeee;
      }
    }
  }
}

.dropdown:hover .dropdown-content {
  display: block;
}
</style>
