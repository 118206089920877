<template lang="pug">
  div.d-flex.flex-column
    HeaderGrid(:name="name" :uid="uid" :dateRanger="dateRanger" userFilter="inventoryFilters" :dateRangeDefault="dateRangeDefault" :tvaModeButton="tvaModeButton" :addButton="addButton" :searchButton="searchButton" :tagguerButton="tagguerButton" :tvaMode="tvaMode" :selectedRows="selectedRows" @setDates="setDates" @updateTvaMode="updateTvaMode" @onFilterTextBoxChanged="onFilterTextBoxChanged" @selectParamerterAction="selectParamerterAction" @clickAction="clickAction" :searchPlaceholder="searchPlaceholder" :archiveMode="archiveMode" :archive="archive" @refreshRow="refreshRow" :indexSearch="indexSearch" :homepageMode="homepageMode" :homepage="homepage")
    b-row.content-scrollable-sticky.m-0.justify-content-center(style="margin-top: -12px !important;")
      b-col#nav-sticky-horizontal.content-scrollable-sticky-inner(cols='12' style='padding: 0 !important; height: 100% !important')
        ul.d-flex
          li
            a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'all' ? 'current' : ''" @click="tabActive = 'all'")
              span Tout ({{ getCountDataByStatus("all") }})
          li(v-for="(status, index) of statusList" :key="index" @click="changeTab(status.value)")
            a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == status.value ? 'current' : ''" @click="tabActive = status.value")
              span {{ status.label }} ({{ getCountDataByStatus(status.value) }})
    .d-flex.w-100.h-100(style="overflow: hidden;")
      .text-center.flex-center.w-100(v-if='isLoadingInventoriesList || isLoadingInventoriesListArchived || !headerLoaded')
        .loading-bg-inner
          .loader
            .outer
            .middle
            .inner
        .mt-5
          br
          br
          br
          | Chargement de la liste des inventaires...
      sync-grid#overviewgrid(ref="overviewgrid" v-else :uid="uid" :gridData="dataFiltered" :name="name" :headerData="headerData" :tagguerButton="tagguerButton" @rowClicked="onRowClicked" @setTagsSelected="setTagsSelected" @setSelectedRows="setSelectedRows" )
    ejs-sidebar.default-sidebar.items-no-padding(v-show="isOpen" ref="sidebar" :type="type" :position="position" :showBackdrop="showBackdrop" :enablePersistence="enablePersistence" :isOpen="isOpen" :closeOnDocumentClick="closeOnDocumentClick")
      sidebar-inventory-content(ref='sidebarInventoryContent' @refreshGrid="refreshGrid" @closeSidebar="closeSidebar")
    popup-new-inventory(ref='popupNewInventory')
  </template>

<script>
import { mapGetters, mapActions } from "vuex";
import { inventoryFormStatus } from "@/types/api-orisis/enums/enums";

import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";
import { setColumnsByObject } from "@/types/api-orisis/library/ObjectOperations.ts";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import SyncGrid from "@/components/global/grid/Grid.vue";
import HeaderGrid from "@/components/global/grid/HeaderGrid.vue";

import PopupNewInventory from "@/components/purchase/inventory/PopupNewInventory.vue";
import SidebarInventoryContent from "@/components/purchase/inventory/SidebarInventoryContent";
import { SidebarComponent } from "@syncfusion/ej2-vue-navigations";

export default {
  components: {
    SidebarInventoryContent,
    SyncGrid,
    HeaderGrid,
    PopupNewInventory,
    "ejs-sidebar": SidebarComponent,
  },
  data() {
    return {
      homepageMode: true,
      homepage: false,
      dateRangeDefault: [
        new Date(
          dayjs().subtract(3, "month").startOf("month").format("YYYY-MM-DD")
        ).toISOString(),
        new Date(dayjs().endOf("month").format("YYYY-MM-DD")).toISOString(),
      ],
      indexSearch: 0,
      isOpen: false,
      position: "Right",
      type: "Push",
      enablePersistence: false,
      closeOnDocumentClick: true,
      showBackdrop: true,
      name: "inventories",
      uid: "inventories",
      archive: false,
      statusList: inventoryFormStatus.filter((el) => !el.notUse),
      headerData: [],
      headerLoaded: false,
      tabActive: "all",
      tagsSelected: [],
      selectedRows: [],

      tvaMode: "HT",
      dateRanger: false,
      tvaModeButton: false,
      addButton: true,
      searchButton: true,
      tagguerButton: false,
      archiveMode: true,
      searchPlaceholder: "Rechercher un inventaire de stock",
    };
  },
  beforeMount() {
    this.headerData = [
      {
        type: "checkbox",
        headerText: null,
        width: 30,
        maxWidth: 30,
        visible: true,
      },
      {
        type: "string",
        field: "status",
        headerText: "Statut",
        width: 100,
        maxWidth: 100,
        template: "statusTemplate",
        nature: "inventoryFormStatus",
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        filterTemplate: "statusFilterTemplate",
      },
      {
        type: "string",
        field: "documentReference",
        headerText: "Numéro",
        width: 160,
        maxWidth: 160,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "date",
        format: "formatDateOptions",
        field: "inventoryDate",
        headerText: "Date",
        width: 95,
        maxWidth: 95,
        template: "dateYmdTemplate",
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "number",
        field: "userId",
        filterTemplate: "userFilterTemplate",
        headerText: "Traité par",
        maxWidth: 150,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        valueAccessor: "user",
      },
      {
        type: "string",
        field: "storageLocation.label",
        headerText: "Point de stockage",
        width: "auto",
        minWidth: 200,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "description",
        headerText: "Description",
        width: "auto",
        minWidth: 200,
        allowFiltering: true,
        allowGrouping: false,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        clipMode: "EllipsisWithTooltip",
        template: "stripHtmlTemplate",
        filter: { type: "Menu", operator: "contains" },
      },
    ];
  },
  computed: {
    ...mapGetters([
      "companiesList",
      "isLoadingInventoriesList",
      "isLoadingInventoriesListArchived",
      "workspaceSelected",
      "institutionsList",
      "userParametersList",
    ]),
    dataOrigine() {
      if (this.archive) {
        return this.$store.getters.inventoriesListArchived;
      } else {
        return this.$store.getters.inventoriesList;
      }
    },
    dataFiltered() {
      if (this.statusList) {
        if (this.tabActive == "all") {
          if (this.archive) {
            return this.$store.getters.inventoriesListArchived;
          } else {
            return this.$store.getters.inventoriesList;
          }
        } else {
          let statusInfos = this.statusList.find(
            (elem) => elem.value == this.tabActive
          );
          if (this.archive) {
            return this.$store.getters[this.name + "ListArchived"].filter(
              (elem) =>
                statusInfos.query
                  ? statusInfos.query.includes(elem.status)
                  : elem.status === this.tabActive
            );
          } else {
            return this.$store.getters[this.name + "List"].filter((elem) =>
              statusInfos.query
                ? statusInfos.query.includes(elem.status)
                : elem.status === this.tabActive
            );
          }
        }
      } else {
        return [];
      }
    },
  },
  async activated() {
    let filter = this.userParametersList.find(
      (el) => el.key == "inventoryFilters"
    );
    if (filter) {
      if (JSON.parse(filter.value).dateRange) {
        this.setDates(JSON.parse(filter.value).dateRange);
      } else {
        this.setDates({
          startDate: dayjs().startOf("year").format("YYYY-MM-DD"),
          endDate: dayjs().endOf("year").format("YYYY-MM-DD"),
        });
      }
    } else {
      await this.setDates({
        startDate: dayjs()
          .subtract(3, "month")
          .startOf("month")
          .format("YYYY-MM-DD"),
        endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
      });
    }
  },
  methods: {
    formatCurrency,
    ...mapActions(["getInventories", "getInventoriesArchived",]),
    setColumnsByObject,
    updateTvaMode(val) {
      this.tvaMode = val;
    },
    async changeTab(tab) {
      this.tabActive = tab;
    },
    onFilterTextBoxChanged(search) {
      this.$refs.overviewgrid.onFilterTextBoxChanged(search);
    },
    async setDates(dates) {
      if (dates.startDate) {
        let dateRange = {
          startDate: new Date(dates.startDate),
          endDate: dates.endDate ? new Date(dates.endDate) : new Date(),
        };
        this.dateRangeDefault = [dateRange.startDate, dateRange.endDate];
        await this.getInventories({
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
        }).then(async (res) => {
          if (res.length) {
            await this.setColumnsByObject(res[0], null, this);
            this.indexSearch++;
          }
          this.headerLoaded = true;
        });
      }
    },
    clickAction() {
      this.$refs.popupNewInventory.toggleModal();
    },
    selectParamerterAction(args) {
      if (["csv", "xls", "print"].includes(args.item.type)) {
        this.$refs.overviewgrid.exportData(args.item.type);
      } else if (args.item.type == "archive") {
        this.getInventoriesArchived({});
        this.archive = true;
      } else if (args.item.type == "current") {
        let filter = this.userParametersList.find(
          (el) => el.key == "inventoryFilters"
        );
        if (filter) {
          this.getInventories({
            startDate: JSON.parse(filter.value).dateRange.startDate,
            endDate: JSON.parse(filter.value).dateRange.endDate,
          });
        } else {
          this.getInventories({
            startDate: dayjs()
              .subtract(3, "month")
              .startOf("month")
              .format("YYYY-MM-DD"),
            endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
          });
        }
        this.archive = false;
      } else {
        this.$refs.overviewgrid.$refs.overviewgrid.openColumnChooser();
      }
    },
    getCountDataByStatus(status) {
      if (status == "all") {
        return this.dataOrigine.length;
      } else {
        let statusInfos = this.statusList.find((elem) => elem.value == status);
        return this.dataOrigine.filter((elem) =>
          statusInfos.query
            ? statusInfos.query.includes(elem.status)
            : elem.status === status
        ).length;
      }
    },
    setTagsSelected(val) {
      this.tagsSelected = val;
    },
    setSelectedRows(val) {
      this.selectedRows = val;
    },
    onRowClicked(event) {
      this.isOpen = true;
      if (!this.archive) {
        this.$refs["sidebarInventoryContent"].getInventoryDetails(
          event.rowData.id
        );
      } else {
        this.$refs["sidebarInventoryContent"].getInventoryArchivedDetails(
          event.rowData.id
        );
      }
      this.$refs.sidebar.toggle();
    },
    refreshRow(val) {
      this.$refs.overviewgrid.$refs.overviewgrid.setRowData(val.id, val);
    },
    refreshGrid(res) {
      this.$refs.overviewgrid.$refs.overviewgrid.refresh();
    },
    closeSidebar() {
      this.$refs.sidebar.hide();
    },
  },
};
</script>
